
// Custom Theming for NG-ZORRO
// For more information: https://ng.ant.design/docs/customize-theme/en
@import "../node_modules/ng-zorro-antd/ng-zorro-antd.less";

// Override less variables to here
// View all variables: https://github.com/NG-ZORRO/ng-zorro-antd/blob/master/components/style/themes/default.less

// @primary-color: #1890ff;
@btn-danger-bg: #f44336;

.ant-table-thead > tr > th {
  background-color: #0077E7;
  color: white;
  white-space: nowrap !important;
  font-size: 1rem !important;
  border: none !important;
}


.ant-table-body > tr {
  border-bottom: 1px solid lightgray;
  &:hover {
    background: lightgray;
  }
}

.ant-table {
  border: 1px solid lightgray;
}

.ant-table-cell-fix-left-last {
    border-right: 1px solid lightgray;
}

.ant-table-cell-fix-right-first {
    border-left: 1px solid lightgray;
}
