/* You can add global styles to this file, and also import other style files */
@import 'src/theme.scss';
// NOTE: Regex code to search for color refs to change: #[0-9a-fA-F]{3,8}
// Change to {6,8} to limit to full hex code search

:root {
  --cf-primary1: #004bff;
  --cf-primary2: #0077e7;
  --cf-primary3: #00c3ff;
  --cf-primary4: #00e7e7;
  --cf-primary5: #00feb7;
  --cf-primary6: #0077e2;
  --cf-primary7: #0777e2;

  --cf-status-green: #4caf50;
  --cf-status-green2: #58c441;
  --cf-status-amber: #ff9800;
  --cf-status-red: #f44336;

  --cf-secondary-blue1: #4b7894;
  --cf-secondary-blue2: #5588a8;
  --cf-secondary-blue3: #7ab7d3;
  --cf-secondary-blue4: #a9dff2;
  --cf-secondary-blue5: #daf4fa;

  --cf-secondary-grey1: #6c6c6c;
  --cf-secondary-grey2: #909090;
  --cf-secondary-grey3: #b1b1b1;
  --cf-secondary-grey4: #d3d3d3;
  --cf-secondary-grey5: #ebebeb;
  --cf-secondary-grey6: #f5f5f5;
  --cf-secondary-grey7: #fafafa;
  --cf-secondary-grey8: #666666;

  --cf-button-outline-grey: #707070;
  --cf-button-outline-teal: #00e7e7;
  --cf-button-grey: #dcdcdc;

  --cf-nav-bar-blue1: #137ec7;
  --cf-nav-bar-blue2: #3683e3;
  --cf-nav-bar-blue3: #44aeef;

  --cf-active-freight-blue1: #1b203c;

  --cf-sea-port: #62b3a6;
  --cf-sea-port-light: #92e3d6;
  /// --cf-sea-vessel: $cf-primary1;
  --cf-air-port: #228386;
  --cf-air-port-light: #52b3b6;
  /// --cf-air-flight: $cf-primary3;

  --cf-white: #ffffff;
  --cf-black: #000000;
  --cf-font-black: #26282d;

  // global custom colors
  --default-background-color: #eeeeee;
  --default-dark-grey: #373d3f;

  // optimal color pallete with progression
  --cf-light-blue: #078cff; // light blue 1
  --cf-light-blue-2: #046cff; // light blue 2
  --cf-blue: #004bff; // blue
  --cf-dark-blue: #0031ad; // dark blue 1
  --cf-dark-blue-2: #012172; // dark blue 2
  --cf-black-blue: #001038; // black-blue
  --cf-dark-orange-brown: #400f03; // dark brown-orange
  --cf-orange-brown: #801f06; // brown-orange
  --cf-yellow-orange: #ff7d00; // yellow-orange
  --cf-orange: #e85000; // orange
  --cf-hot-orange: #ff3f0d; // hot orange

  --cf-menu-color: #cfd3de;
  --cf-select-menu-background: #333c4d;
  --cf-sidenav-line-background: #8f939a;

  --cf-edit-btn: #fd9827;
  --cf-download-btn: #22c4fd;
  --cf-delete-btn: #f2453d;
  --cf-report-board: #dcdcdc;
  --cf-report-filter-btn: #0777e2;
  --cf-report-switch: #287dfd;
}

$cf-light-blue: var(cf-light-blue);
$cf-light-blue-2: var(cf-light-blue-2);
$cf-blue: var(--cf-blue);
$cf-dark-blue: var(--cf-dark-blue);
$cf-dark-blue-2: var(--cf-dark-blue-2);
$cf-black-blue: var(--cf-black-blue);
$cf-dark-orange-brown: var(cf-dark-orange-brown);
$cf-orange-brown: var(--cf-orange-brown);
$cf-yellow-orange: var(--cf-yellow-orange);
$cf-orange: var(--cf-orange);
$cf-hot-orange: var(--cf-hot-orange);

$cf-primary1: var(--cf-primary1);
$cf-primary2: var(--cf-primary2);
$cf-primary3: var(--cf-primary3); // match with CF logo
$cf-primary4: var(--cf-primary4);
$cf-primary5: var(--cf-primary5);
$cf-primary6: var(--cf-primary6);
$cf-primary7: var(--cf-primary7);

$cf-button-green: var(--cf-status-green);
$cf-button-green2: var(--cf-status-green2);
$cf-button-blue: var(--cf-nav-bar-blue1);
$cf-button-red: var(--cf-status-red);
$cf-button-grey: var(--cf-button-grey);
$cf-status-red: var(--cf-status-red);
$cf-status-amber: var(--cf-status-amber);
$cf-status-green: var(--cf-status-green);

$cf-secondary-blue1: var(--cf-secondary-blue1);
$cf-secondary-blue2: var(--cf-secondary-blue2);
$cf-secondary-blue3: var(--cf-secondary-blue3);
$cf-secondary-blue4: var(--cf-secondary-blue4);
$cf-secondary-blue5: var(--cf-secondary-blue5);

$cf-secondary-grey1: var(--cf-secondary-grey1);
$cf-secondary-grey2: var(--cf-secondary-grey2);
$cf-secondary-grey3: var(--cf-secondary-grey3);
$cf-secondary-grey4: var(--cf-secondary-grey4);
$cf-secondary-grey5: var(--cf-secondary-grey5);
$cf-secondary-grey6: var(--cf-secondary-grey6);
$cf-secondary-grey7: var(--cf-secondary-grey7);
$cf-secondary-grey8: var(--cf-secondary-grey8);

$cf-button-outline-grey: var(--cf-button-outline-grey);
$cf-button-outline-teal: var(--cf-button-outline-teal);
$cf-status-green: var(--cf-status-green);
$cf-status-red: var(--cf-status-red);

$cf-nav-bar-blue1: var(--cf-nav-bar-blue1);
$cf-nav-bar-blue2: var(--cf-nav-bar-blue2);
$cf-nav-bar-blue3: var(--cf-nav-bar-blue3);

$cf-active-freight-blue1: var(--cf-active-freight-blue1);

$cf-sea-port: var(--cf-sea-port);
$cf-sea-port-light: var(--cf-sea-port-light);
$cf-air-port: var(--cf-air-port);
$cf-air-port-light: var(--cf-air-port-light);

$cf-white: var(--cf-white);
$cf-black: var(--cf-black);
$cf-font-black: var(--cf-font-black);

$default-background-color: var(--default-background-color);
$default-dark-grey: var(--default-dark-grey);

$cf-menu-color: var(--cf-menu-color);
$cf-select-menu-background: var(--cf-select-menu-background);
$cf-sidenav-line-background: var(--cf-sidenav-line-background);

$cf-edit-btn: var(--cf-edit-btn);
$cf-download-btn: var(--cf-download-btn);
$cf-delete-btn: var(--cf-delete-btn);
$cf-report-board: var(--cf-report-board);
$cf-report-filter-btn: var(--cf-report-filter-btn);
$cf-report-switch: var(--cf-report-switch);

html,
body {
  min-height: 100vh;
  font-family: 'Hind', sans-serif !important;
  color: $cf-font-black !important;
  font-size: 16px;
}

.row {
  margin: 0px;
}

.text-overflow {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.box-shadow {
  box-shadow: 3px 3px 5px $cf-secondary-grey4;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: $cf-white !important;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: $cf-primary2 !important;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: $cf-secondary-grey5;
}

::-webkit-scrollbar-thumb {
  background: $cf-secondary-grey2;
}

::-webkit-scrollbar-thumb:hover {
  background: $cf-secondary-grey1;
}

// Added to fix a bug drag and drop functionality not working within mat-dialog
// See https://github.com/angular/components/issues/15880
html.cdk-global-scrollblock {
  position: initial !important;
}

button:focus {
  outline: none;
}

.pointer {
  cursor: pointer;
}

app-svg .mat-badge-content {
  top: -15px !important;
  right: -15px !important;
}

::ng-deep .dragDrop mat-icon {
  cursor: grab !important;
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.4);
}

h1,
h2,
h3,
h4 {
  cursor: default;
}

.mat-tooltip.largerTooltip {
  font-size: 0.8em;
  background-color: #707070;
  margin: 0px;
}

.inset-mat-card {
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.12), inset 0 1px 2px rgba(0, 0, 0, 0.24) !important;
  background-color: $default-background-color;
}

.align-center {
  justify-content: center;
  text-align: center;
}

.align-right {
  justify-content: right;
  text-align: right;
}

// material menu classes
.alert-menu-panel {
  width: fit-content;
  height: fit-content;
}

.column-menu-panel {
  width: fit-content;
  height: fit-content;
  padding: calc(1em - 8px) 1em;
}

.mat-option-text,
.mat-select-value {
  font-size: 16px;
}

// mat-table
.mat-table {
  border-radius: 5px;

  .table-body {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }
}

// toaster styles
.toast-success {
  background-color: $cf-status-green !important;
}
.toast-error {
  background-color: $cf-status-red !important;
}
.toast-info {
  background-color: $cf-primary2 !important;
}
.toast-error {
  background-color: $cf-status-amber !important;
}

// Added to fix a bug drag and drop functionality not working within mat-dialog
// See https://github.com/angular/components/issues/15880
html.cdk-global-scrollblock {
  position: initial !important;
}

::ng-deep .dragDrop mat-icon {
  cursor: grab !important;
}

.column-customizer-content {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
}

.vertical-align-label {
  display: flex;
  align-items: center;
}

// ROW UTILITIES
.row-space-between {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 0;
}

.row-left {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  margin-right: 8px;
}

.button-row {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;

  > * {
    margin-left: 8px;
  }
}

// MAT-FORM-FIELD OVERRIDES
::ng-deep .mat-form-field-prefix {
  top: -10px !important;
  filter: invert(100%);
}

::ng-deep .mat-form-field-outline {
  background-color: $cf-white;
  border-radius: 6px;
}

::ng-deep .mat-form-field-appearance-outline .mat-form-field-outline .mat-form-field-should-float .mat-focused :active {
  color: $cf-white !important;
  text-decoration: underline white;
}

.userMenu + * .mat-menu-panel {
  background-color: $cf-white;
  position: fixed;
  top: 80px;
  right: 1px;
  padding: 16px;
  max-height: calc(100vh - 120px);
  overflow-y: hidden;

  .mat-menu-content {
    padding: 0;
  }
}

.mat-tab-group {
  width: 100%;
}

.mat-tab-header {
  padding-top: 10px;
  border-bottom: none;
  background-color: $default-background-color;

  .mat-ripple-element {
    display: none;
  }
}

.mat-ink-bar {
  background-color: $cf-primary2;
}

.card-header-align-right {
  display: flex;
  flex-direction: row-reverse;
}

.multiline-mat-option {
  height: unset !important;
  white-space: pre !important;
  line-height: 1.2em !important;
  padding-top: 0.7em !important;
  padding-bottom: 0.7em !important;
}

mat-divider {
  width: 100%;
}

.detail-divider {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

.extended-width {
  width: 90%;
}

.cf-header-text {
  text-transform: capitalize;
  font-size: 1.2rem;
  font-weight: 600;
}

.mat-dialog-actions {
  button {
    font-size: 16px;
  }
}

::ng-deep .input-container {
  margin: 0 auto;
  width: 95%;
  padding-top: 1rem;
}

.cf-page-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;

  .cf-page-header-text {
    font-size: 26px;
    text-transform: uppercase;
    font-weight: bolder;
    margin-bottom: 0;
    color: #000000;

    .mat-chip {
      font-size: 12px;
    }
  }

  .cf-page-header-toolbar {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: flex-end;

    button {
      font-size: 16px;
    }

    > * {
      margin-left: 0.5rem;
    }
  }
}

@keyframes downloadSpinner {
  to {
    transform: rotate(360deg);
  }
}

.download-spinner:before {
  content: '';
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  margin-left: -10px;
  border-radius: 50%;
  border: 2px solid #ffffff;
  border-top-color: #000000;
  animation: downloadSpinner 0.8s linear infinite;
}

.checkbox-form-field {
  .mat-form-field-flex {
    height: 64px;

    .mat-checkbox {
      margin-top: -6px;
    }
  }
}

.tab-badge {
  margin-right: 15px;
  margin-top: -4px;
  margin-left: 4px;
}

.mat-tooltip.largerTooltip {
  font-size: 0.8em;
  background-color: #707070;
  margin: 0px;
}

.color-green {
  color: #4aaf35 !important;
}

.color-red {
  color: #f9515b !important;
}

.color-orange {
  color: #fb9826 !important;
}
